import styled from 'styled-components';
import tw from 'tailwind.macro';

export const Title = styled.h1`
  ${tw`mb-4 text-2xl font-semibold`};
`;

export const Image = styled.figure`
  ${tw`w-full mt-4 mb-10 overflow-hidden rounded-lg`};
`;

export const Links = styled.div`
  ${tw`flex justify-between w-full mt-10`};
`;
